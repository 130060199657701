<template>
  <div id="main-app">
    <div  v-if="loading" class="spin-container">
      <a-spin />
    </div>
    <a-layout id="main-layout-grid" v-else>
      <a-layout>

        <a-layout-sider v-if="!loading"  breakpoint="lg">
          <div class="menu-container">
            <div class="logo-box">
              <a-col class="logo-container">
                <router-link :to="{ name: isAdminSection ? 'objects' : 'main' }" class="logo" title="2021.0505.1238">
                  <img class="logo-img" src="/images/Logo4.svg" alt="4Space">
                  <div class="logo-img-text">
                    <img class="anima" src="/images/Anima.svg" alt="4Space">
                    <span class="logo-text">Space</span>
                  </div>
                </router-link>
              </a-col>
            </div>

            <a-menu class="main-nav-menu" mode="inline" v-if="isAdminSection" v-model:selectedKeys="selectedMenuItem"
              :style="{ height: 'fit-content' }" breakpoint="lg">
              <a-menu-item key="objects">
                <router-link :to="{ name: 'objects' }">
                  <apartment-outlined />
                  <span>Структура</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="types">
                <router-link :to="{ name: 'types' }">
                  <database-outlined />
                  <span>Типы объектов</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="politics">
                <router-link :to="{ name: 'politics' }">
                  <control-outlined />
                  <span>Политика доступов</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="maps">
                <router-link :to="{ name: 'maps' }">
                  <environment-outlined />
                  <span>Карты</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="reservations">
                <router-link :to="{ name: 'reservations' }">
                  <calendar-outlined />
                  <span>Бронирования</span>
                </router-link>
              </a-menu-item>
            </a-menu>
            <a-menu class="main-nav-menu" mode="inline" v-if="!isAdminSection" v-model:selectedKeys="selectedMenuItem"
              :style="{ height: 'fit-content' }" breakpoint="lg">


              <a-menu-item key="main">
                <router-link :to="{ name: 'main' }">
                  <home-outlined />
                  <span>Главная</span>
                </router-link>
              </a-menu-item>

              <a-menu-item key="reservations">
                <router-link :to="{ name: 'myReservations' }">
                  <calendar-outlined />
                  <span>Забронированные</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="slots">
                <router-link :to="{ name: 'findSlots' }">
                  <search-outlined />
                  <span>Свободные</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="favourites">
                <router-link :to="{ name: 'favourites' }">
                  <heart-outlined />
                  <span>Избранные</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="maps">
                <router-link :to="{ name: 'mapView', params: { id: 'root' } }" style="display: flex; align-items: center;">
                  <img class="anticon" src="/images/icons/map.svg"  style="margin-right: 8px; min-width: 20px;" v-show="route.name !== 'mapView'" />
                  <img class="anticon" src="/images/icons/map-outlined2.svg"  style="margin-right: 8px; min-width: 20px;" v-show="route.name === 'mapView'" />
                  <span>Карта</span>
                </router-link>
              </a-menu-item>
            </a-menu>


            <div class="user-info">
              <span class="ant-dropdown-link text-color-text">
                <a-dropdown placement="topRight" v-model:visible="roleMenuVisible">
                  <div class="wrap-dropdown-user">
                    <span class="desktop-lg">
                        <a-avatar :src="user?.attributes?.avatar_url && user?.attributes?.avatar_url.length && user?.attributes?.avatar_url[0] || ''" :size="36" :style="{ marginRight: '0.5rem', borderRadius: '100%' }">
                          <template #icon>
                            <user-outlined />
                          </template>
                         </a-avatar>
                    </span>

                    <span class="mobile-lg">
                       <a-avatar :src="user?.attributes?.avatar_url && user?.attributes?.avatar_url.length && user?.attributes?.avatar_url[0] || ''" :size="36" :style="{ marginRight: '0.5rem', borderRadius: '100%' }">
                        <template #icon>
                          <user-outlined />
                        </template>
                     </a-avatar>
                    </span>

                     <span class="desktop-lg user-name clickable-image" v-if="user">
                         <span class="user-name-f">{{ user.firstName }}</span>
                         <down-outlined :style="{ fontSize: '14px', marginLeft: '0.25rem', opacity: 0.5 }" />
                     </span>
                  </div>
                  <template #overlay>
                    <a-menu class="menu-dropdown">
                      <a-menu-item v-if="role === 'admin' && !isAdminSection">
                        <a @click="changeRole('admin')" style="display: flex; align-items: center"> <img src="/img/menu/icon_admin.svg" alt="Админ"> &nbsp; <div>Перейти к админ <br> панели</div></a>
                      </a-menu-item>

                      <a-menu-item v-if="role === 'admin' && isAdminSection">
                        <a @click="changeRole('user')" style="display: flex; align-items: center"> <img src="/img/menu/icon_user.svg" alt="Пользователь"> <div>Перейти к интерфейсу <br> пользователя</div></a>
                      </a-menu-item>

                      <a-menu-item @click="orderBadge" v-if="role === 'admin' && !isAdminSection">
                        <a><img src="/img/menu/icon_pass.svg" alt="Пропуск"> Заказать пропуск</a>
                      </a-menu-item>

                      <a-menu-item @click="showDrawer" v-if="role === 'admin' && !isAdminSection">
                        <a><img src="/img/menu/icon_user.svg" alt="Пользователь"> Профиль</a>
                      </a-menu-item>

                      <a-menu-item>
                        <a @click="userLogout"><img src="/img/menu/icon_logout.svg" alt="Выход"> Выйти</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </span>
            </div>

          </div>

        </a-layout-sider>
        <a-layout>
          <a-layout-content style="padding: 1rem 2rem 4rem 2rem;">
            <a-config-provider :locale="ru_RU">
              <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                  <component :is="Component"></component>
                </transition>
              </router-view>
            </a-config-provider>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>

  <!-- Драйвер с профилем пользователя -->
  <a-drawer title="Профиль пользователя" placement="right" :closable="true" @close="closeDrawer" v-model:visible="userProfile.isShowDrawer"
    :after-visible-change="afterVisibleChange" :width='412' :headerStyle="{
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      color: '#1A1A23',
      fontFamily: 'Aeroport',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '31px',
    }">

<!--    <pre>{{userProfile}}</pre>-->

    <template v-if="userProfile?.user">

      <a-avatar :src="userAvatar || userProfile?.user.attributes?.avatarUrl || ''" :size="160"
                :style="{ marginRight: '0.5rem', borderRadius: '100%', marginBottom: '32px' }">
        <template #icon>
          <user-outlined />
        </template>
      </a-avatar>

      <p :style="{
      overflow: 'hidden',
      color: '#1A1A23',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontFamily: 'Arial',
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '2rem',
      marginBottom: '2px',
    }">
         {{ userProfile.user?.firstName }} {{ userProfile.user?.lastName }}
<!--        Карпов Константин-->
      </p>

      <p :style="{ fontFamily: 'Arial', lineHeight: '1.5rem', }">
        {{userProfile.user?.attributes?.role?.join(', ')}}
      </p>

      <div v-if="userProfile.user?.attributes?.messanger" :style="{ display: 'flex', gap: '0.5rem', alignItems: 'center' }">
        <MessageOutlined />
        <a target="_blank" :href="`https://telegram.me/${userProfile.user?.attributes?.messanger}`">
          {{userProfile.user?.attributes?.messanger?.join(', ')}}</a>
      </div>

      <div :style="{ display: 'flex', gap: '0.5rem', alignItems: 'center' }">
        <MailOutlined />
        <a :href="'mailto:' + userProfile.user?.email">
          {{ userProfile.user?.email }}</a>
      </div>

      <div v-if="userProfile.user?.attributes?.phone" :style="{ display: 'flex', gap: '0.5rem', alignItems: 'center' }">
        <PhoneOutlined />
        <a :href="'tel:' + userProfile.user?.attributes?.phone">{{userProfile.user?.attributes?.phone?.join(', ')}}</a>
      </div>
    </template>
  </a-drawer>
  <!--./Драйвер с профилем пользователя -->

  <!-- Модалка заказа пропуска -->
  <a-modal :afterClose="imageUrl = ''" v-model:visible="orderBadgeModalVisible" title="Заказ пропуска" width="546px">


    <!-- <a-form layout="vertical" ref="refFormInfo" :model="formInfo" name="form_in_modal" :label-row="{ span: 18 }"
      noStyle="true"> -->
    <a-form :model="formData" name="basic" autocomplete="off" @finish="onSubmit" @finishFailed="onError">

      <div style="display: flex; column-gap: 1.5rem;">

        <a-form-item label="Фотография" :rules="[{ required: true, message: '' }]" name="imageUrl"
          style="width: min-content;">
          <a-upload v-model:value="imageUrl" v-model:file-list="fileList" :show-upload-list="false" :max-count="1"
            :beforeUpload="beforeUpload" :file-list="fileList" accept="image/png, image/jpeg, image/jpeg"
            list-type="picture-card" :multiple="false" style="padding: 0;">
            <img class="image-uploaded" v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <PlusOutlined style="opacity: 0.48" />
            </div>
          </a-upload>
        </a-form-item>
        <div style="display: flex; flex-direction: column; column-gap: 1.3rem;">

          <a-form-item name="surname" label="Фамилия" :rules="[{ required: true, message: 'Введите фамилию!' }]">
            <a-input class="input" v-model:value="formData.surname"
              style="padding: 8px 20px; border-radius: 12px; background-color: #fff; border: 1px solid rgba(26, 26, 35, 0.16);" />
          </a-form-item>

          <a-form-item name="name" label="Имя" :rules="[{ required: true, message: 'Введите имя!' }]">
            <a-input class="input" v-model:value="formData.name"
              style="padding: 8px 20px; border-radius: 12px; background-color: #fff; border: 1px solid rgba(26, 26, 35, 0.16);" />
          </a-form-item>

          <a-form-item name="company" label="Компания">
            <a-input class="input" v-model:value="formData.company"
              style="padding: 8px 20px; border-radius: 12px; background-color: #fff; border: 1px solid rgba(26, 26, 35, 0.16);" />
          </a-form-item>
        </div>
      </div>
    </a-form>

    <template #footer>
      <a-button class="cancelButton" key="cancel" size="large" @click="orderBadgeModalVisible = false">
        Отменить
      </a-button>
      <a-button class="submitButton" key="submit" size="large" type="primary" @click="orderBadgeClose"
        :disabled="!formData.name || !formData.surname || !imageUrl">
        Отправить
      </a-button>
    </template>

  </a-modal>
</template>

<script>
import { keycloak } from "./main";
import { computed, ref } from "@vue/reactivity";
import { onMounted, unref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {
  DownOutlined,
  UserOutlined,
  ApartmentOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  ControlOutlined,
  PlusCircleOutlined,
  EnvironmentOutlined,
  StarOutlined,
  HomeOutlined,
  MessageOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  HeartOutlined,
    SearchOutlined,
    HeatMapOutlined,
} from '@ant-design/icons-vue';
import AppInputFile from "@/components/admin/shared/ul/AppInputFile";
import { ACTION_TYPES } from "@/constants/action-types";
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU';
import { notification } from "ant-design-vue";
import { fileHelper } from "@/compositions/commonFunctions";
import {USER_PROFILE, USER_PROFILE_CLOSE} from "./store/settings";

export default {
  name: "App",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);
    const orderBadgeModalVisible = ref(false);
    const profileDriverVisible = ref(false);
    const userProfile = computed(() => store.getters[USER_PROFILE]);
    const roleMenuVisible = ref(false);
    const refFormInfo = ref(null);
    const imageUrl = ref('');
    const fileList = ref([])
    const formData = ref({
      name: "",
      surname: "",
      company: "",
    });
    const userAvatar = ref('');
    const collapsed = ref(true);
    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    const onSubmit = async (_formData) => {
      console.log("Form Submitted!:", _formData);

    };

    // eslint-disable-next-line no-unused-vars
    async function isLoadImg(avatar_img = '') {
      //TODO
      const res = await new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
          resolve(true);
        };
        img.onerror = function () {
          resolve(false);
        };
        img.src = avatar_img;
      });

       console.log('isLoadImg',avatar_img);
      return {
        isHasLink: res && avatar_img && avatar_img === '',
        link: avatar_img
      }
    }

    const onError = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const selectedMenuItem = computed({
      get: () => [route?.meta?.menuItem],
      set: (link) => console.log(link)
    })

    async function beforeUpload(file) {
      console.log(file);
      fileList.value = [file];
      imageUrl.value = await fileHelper.createBase64FromFile(file);
      return false;
    }
    // const userAvatar = computed( async() => {
    //   const res  = await isLoadImg(userProfile.value?.user?.attributes?.avatarUrl || '');
    //   return {
    //     isHasLink: res.isHasLink,
    //     link: res.link,
    //   }
    // });
    const user = computed(() => {
      return store.state.cabinet.user;
    });
    const role = computed(() => store.state.cabinet.role);
    const routePath = computed(() => route?.path || '');
    const isAdminSection = computed(() => unref(routePath).indexOf('/admin/') === 0);


    // Первоначальная загрузка проекта
    onMounted(async () => {
      // formInfo.surename = null;
      // Инициализировать первоначальные данные
      await store.dispatch(ACTION_TYPES.CABINET.LOAD_ME);
      loading.value = false;
      checkRouteAccess(unref(routePath), null);
    });

    watch(routePath, (to, from) => {
      checkRouteAccess(to, from);
    });

    function checkRouteAccess(to, from) {
      // Пользователю нельзя в админку
      if (unref(role) !== 'admin' && to?.indexOf('/admin') >= 0) {
        if (from) {
          notification.error({ message: 'Доступ запрещён', description: 'Это раздел только для администраторов' });
        }
        return router.push({ name: 'index' });
      }
    }

    function changeRole(role) {
      roleMenuVisible.value = false;
      if (role === 'user') {
        router.push({ name: 'main' });
      }
      if (role === 'admin') {
        router.push({ name: 'objects' });
      }
    }

    function orderBadge() {
      orderBadgeModalVisible.value = true;
    }

    function orderBadgeClose() {
      orderBadgeModalVisible.value = false;
      notification.info({
        message: "Запрос отправлен",
        description: "Ожидайте сообщения о статусе вашего пропуска",
      });
    }

    const showDrawer = async () => {
      profileDriverVisible.value = true;
      let avatar = '';
      if(user.value?.attributes?.avatar_url && user.value.attributes.avatar_url?.length) {
        avatar = user.value?.attributes?.avatar_url[0];
      } else if(user.value?.attributes?.avatar_url) {
        avatar = user.value?.attributes?.avatar_url || '';
      }
     // const res = await isLoadImg(avatar);
      if (avatar) {
        userAvatar.value = avatar;
      }


      store.dispatch(USER_PROFILE, {
            user: user.value,
            isShowDrawer: true,
      });

    };

    const closeDrawer = () => {
      store.dispatch(USER_PROFILE_CLOSE);
      userAvatar.value = '';
    };

    function userLogout() {
      keycloak.logout();
    }

    // View Port
    setViewportParameters();
    setScrollPosition();
    window.addEventListener("resize", setViewportParameters, false);
    window.addEventListener("scroll", setScrollPosition, false);

    function setViewportParameters() {
      store.commit('setViewportWidth', window.innerWidth);
      store.commit('setViewportHeight', window.innerHeight);
    }

    function setScrollPosition() {
      store.commit('setScrollPosition', window.scrollY);
    }
    function afterVisibleChange() {
      console.log('afterVisibleChange');
    }

    return {
      collapsed,
      toggleCollapsed,
      userAvatar,
      loading, isAdminSection,
      selectedMenuItem,
      user, changeRole, userLogout, roleMenuVisible,
      role,
      ru_RU,
      orderBadgeModalVisible,
      orderBadge,
      showDrawer,
      closeDrawer,
      orderBadgeClose,
      afterVisibleChange,
      profileDriverVisible,
      refFormInfo,
      imageUrl,
      formData,
      userProfile,
      onError, onSubmit, beforeUpload,
      route,
    };
  },
  components: {
    DownOutlined,
    UserOutlined,
    ApartmentOutlined,
    DatabaseOutlined,
    CalendarOutlined,
    ControlOutlined,
    PlusCircleOutlined,
    EnvironmentOutlined,
    StarOutlined,
    HomeOutlined,
    MessageOutlined,
    MailOutlined,
    PhoneOutlined,
    PlusOutlined,
    AppInputFile, HeartOutlined, SearchOutlined, HeatMapOutlined,
  },
}
</script>

<style lang="less">
.wrap-global-loading {
}

.ant-drawer-close {
  height: 90px;
}

.ant-modal-close {
  top: 22px !important;
  right: 9px !important;
}

.ant-modal-title {
  color: #1A1A23;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-family: Arial !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 2rem !important;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
  padding: 0 !important;
}

.image-uploaded {
  height: 180px;
  width: 180px;
  border-radius: 12px;
}

.ant-modal-footer {
  text-align: end !important;
}

.cancelButton {
  border-radius: 12px !important;
  border: 1px solid rgba(26, 26, 35, 0.16) !important;
}

.ant-form-item-control-wrapper {
  width: 100%;
}

.ant-form-item {
  width: 100%;
}

.ant-form-item-label {
  line-height: unset !important;
}

label::after {
  color: rgb(255, 0, 0) !important;
}

label {
  color: rgba(26, 26, 35, 0.48) !important;
  font-family: Aeroport !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;

}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 0 !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 180px !important;
  height: 180px !important;
  border-radius: 12px !important;
  background-color: #FFFFFF !important;
}

#main-app {
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

.ant-layout-sider {

  min-width: 270px !important;

  @media (max-width: 1180px) {
    min-width: 240px !important;
  }
  @media (max-width: 992px) {
    min-width: 80px !important;
  }
}

.logo-box {
  position: sticky;
  top: 0px;
}

.menu-container {
  display: flex;
  height: 100svh;
  flex-direction: column;

  position: fixed;
}

.user-info {
  padding: 1.5rem;
  display: flex;
  margin-top: auto;

  .ant-dropdown-link {
    display: flex;
    align-items: center;
    .ant-dropdown-trigger {
      height: 25px;
    }
  }

  .wrap-dropdown-user {
    display: flex;
    align-items: center;
  }
}

.menu-dropdown {
  position: relative;
  top: -12px;

  .ant-dropdown-menu-item {
    padding: 12px 18px !important;

    img {
      padding-right: 10px;
    }
  }
}

.anima {
  max-width: 46px;
  max-height: 12px;
}

.logo-img-text {
  display: flex;
  flex-direction: column;

  .logo-text {
    color: #1A1A23;
    font-family: Aeroport;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    /* 124% */
    letter-spacing: -0.5px;
  }
}

#main-layout-grid {
  min-height: 100%;
  background: #FFFFFF !important;
  max-width: 1440px;

  .main-header {
    height: 5rem; // 80
    line-height: 5rem;
    padding: 0 2rem;
    background-color: @background-primary;
  }

  .logo-container {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      padding: 15px 10px;
      .logo-text {
        font-size: 20px;
      }
    }

    .logo {
      column-gap: 12px;
      //width: 260px;
      // margin-right: 10px;
      display: inline-flex;
      position: relative;

      @media (max-width: 992px) {
        width: auto;
      }

      &-img {
        @media (max-width: 992px) {
          display: none;
        }

        &-short {
          display: none;

          @media (max-width: 500px) {
            display: block;
          }
        }
      }
    }

    .header-search {
      max-width: 100%;
      width: 300px;
      border: none;

      @media (max-width: 600px) {
        width: 200px;
      }
    }
  }

  .ant-layout {
    background: #FFFFFF !important;
    max-width: 1440px;

    &-sider {
      background: @background-primary;
      border-right: none !important;

      .ant-menu {
        background: transparent;
        padding: 0rem 1rem 0 1rem;
        border-right: none !important;

        .ant-menu-item {
          height: 36px;
          line-height: 36px;
          margin-bottom: 4px;

          @media (max-width: 1180px) {
            padding-left: 12px !important;
          }
          @media (max-width: 992px) {
            padding-left: 15px !important;
          }
        }

        .ant-menu-item-selected {
          background: linear-gradient(270deg, #139EA3 0%, #5EBDC0 98.8%);

          :hover {
            color: #FFFFFF;
          }
        }

        :hover {
          color: unset;
        }
      }
    }

    &-content {
      // border-radius: 0px 0 0 0;
      background: white;
      padding: 1rem 2.5rem 4rem 2.5rem;
    }
  }

  .user-name-menu {
    .user-name {
      margin-right: 1rem;
      display: flex;
      align-items: center;

      @media (max-width: 700px) {
        display: none;
      }
    }
  }
  @media (max-width: 1180px) {
    .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
      width: 98%;
    }
  }
  .clickable-image {

    .anticon-down {
      position: relative;
      top: -4px;
    }
    @media (max-width: 1180px) {
     // top: 0px;
    }
  }

  .user-name-f {
    overflow: hidden;
    max-width: 170px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    margin-right: 5px;
    @media (max-width: 1180px) {
      max-width: 140px;
    }
  }

}
</style>
