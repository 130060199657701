import { ACTION_TYPES } from "@/constants/action-types";
import { keycloak } from "@/main";

const state = () => ({
  user: {},
  role: 'admin',
});

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  setRole: (state, role) => {
    state.role = role;
  },
};

const actions = {
  [ACTION_TYPES.CABINET.LOAD_ME]: async ({ commit }) => {
    const user = await keycloak.loadUserProfile();
    const tokenData = keycloak.idTokenParsed;
    commit('setUser', { ...user, id: tokenData?.sub });
    // Проверяем список доступных ролей пользователя
    if (tokenData?.roles.indexOf('admin') !== -1) {
      commit('setRole', 'admin');
    } else {
      commit('setRole', 'user');
    }
    return user;
  },
  [ACTION_TYPES.CABINET.SET_ROLE]: async ({ commit }, role) => {
    commit('setRole', role);
    return role;
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: false,
};
