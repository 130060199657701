import { createApp } from "vue";
import { createStore } from "vuex";
import { router } from "./router";
import { store } from "./store";
import Axios from 'axios'
import Keycloak from 'keycloak-js';
import { notification } from 'ant-design-vue';
import humps from 'humps';
import moment from 'moment';

import App from "./App.vue";
import { useAntDesign } from "@/ant";

import './service-worker'

moment.locale('ru');
const app = createApp(App);
app.use(router);
app.use(createStore(store));

// Ant design
useAntDesign(app);

// Global Components
// ...

// Keycloak init and intercept to axios
export const keycloak = Keycloak('/keycloak.json');

keycloak.init({
  onLoad: 'check-sso',
  promiseType: 'native',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256',
})
  .then((authenticated) => {
    if (authenticated) {
      console.log('auth');
      app.mount("#vue3-app");

    } else {
      console.warn('not authenticated!');
      keycloak.login();
    }
  });

// Axios configuration
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// Intercept request ->
Axios.interceptors.request.use(function (config) {

  if (config.url.indexOf('ms-qut-filestorage') !== -1 && config.method === 'get') {
    config.headers = {};
  } else {
    config.headers = {
      'Authorization': `Bearer ${keycloak.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  //
  if (!(config.data instanceof FormData)) {
    config.data = humps.decamelizeKeys(config.data);
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

// Intercept response <-
function handleError(errorCode, errorData) {
  if (errorCode === 401) {
    // Эта ошибка может возникнуть дважды, если выполняются асинхронные запросы,
    // лучше её не показывать
    keycloak.logout();
  }
  console.log(errorData);
  if (errorData?.message) {
    notification.error({ message: 'Ошибка', description: errorData?.message });
  } else if (Array.isArray(errorData)) {
    errorData.forEach((field) => notification.error({ message: 'Ошибка', description: field?.error }));
  } else {
    if (errorCode === 400) {
      notification.error({ message: 'Ошибка валидации', description: 'Отправленные данные неверны' });
    }
    if (errorCode === 403) {
      notification.error({ message: 'Ошибка доступа', description: 'Нет доступа к ресурсу' });
    }
    if (errorCode === 404) {
      notification.error({ message: 'Ошибка 404', description: 'Ресурс не найден' });
    }
    if (errorCode === 413) {
      notification.error({ message: 'Ошибка 413', description: 'Размер файла слишком большой' });
    }
    if (errorCode === 422) {
      notification.error({ message: 'Ошибка валидации', description: 'Отправленные данные неверны' });
    }
    if (errorCode === 500) {
      notification.error({
        message: 'Ошибка сервера (500)',
        description: 'Произошла ошибка выполнения. Повторите действие позже'
      });
    }
    if (errorCode === 502) {
      notification.error({
        message: 'Ошибка сервера (502)',
        description: 'Произошла ошибка выполнения. Повторите действие позже'
      });
    }
  }
}

Axios.interceptors.response.use(function (response) {
  // kraken error
  if (response.data.error_backend) {
    console.log('🐙', response.data.error_backend?.http_status_code);
    const errorCode = +response.data.error_backend?.http_status_code;
    const errorData = JSON.parse(response.data.error_backend?.http_body || "{}");
    handleError(errorCode, errorData);
    return Promise.reject({
      status: errorCode || 500,
      data: errorData
    });
  }
  response.data = humps.camelizeKeys(response.data);
  return response.data;
}, function (error) {
  console.error('axios intercept error', error, error?.response, error.response?.status);
  handleError(+error.response?.status, error.response?.data);
  return Promise.reject(error);
})
