<template>
  <section class="root-node">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: "RootNode",
}
</script>

<style scoped>
.root-node {
  height: 100%;
}
</style>