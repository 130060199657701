<template>
  <a-upload :show-upload-list="true" :file-list="fileList"
            accept="image/png, image/jpeg, image/jpeg"
            :beforeUpload="beforeUpload" :remove="handleRemove"
            :locale="{ removeFile: 'Удалить файл' }"
            class="plain-input-file">
    <span class="ant-upload-placeholder">{{ fileList.length === 0 ? placeholder : 'Изображение выбрано' }}</span>
    <a-button size="large">Выбрать</a-button>
  </a-upload>
</template>

<script>
import { nextTick } from "@vue/runtime-core";
import { computed } from "@vue/reactivity";

export default {
  name: 'AppInputFile',
  props: {
    modelValue: Array,
    placeholder: String,
  },
  setup(props, { emit }) {

    const fileList = computed({
      get: () => props.modelValue,
      set: async (newValue) => {
        nextTick(() => emit('update:modelValue', newValue));
      }
    });

    function beforeUpload(file) {
      if (fileList.value.length > 0) {
        fileList.value = [file];
        return false;
      }
      fileList.value = [...fileList.value, file];
      return false;
    }

    function handleRemove(file) {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    }

    return { fileList, beforeUpload, handleRemove }
  },
}
</script>

<style lang="less">
.plain-input-file {
  display: block;

  .ant-upload {
    height: 40px;
    display: block;
    background-color: @background-primary;

    &-placeholder {
      color: @disabled-color;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      line-height: 40px;
    }

    .ant-btn {
      float: right;
    }
  }
}
</style>
