import Axios from "axios";
import { urlHelper } from "@/compositions/commonFunctions";
// Получения списка политик бронирования
export const FETCH_RESERVATION_POLICIES = '[politics] Fetch Reservation Policies';
export const GET_RESERVATION_POLICIES = '[politics] Get Reservation Policies';
export const SET_RESERVATION_POLICIES = '[politics] Set Reservation Policies';

// Добавить/редактировать/удалить
export const RESERVATION_POLITIC = '[politics] Reservation Politic';
export const GET_RESERVATION_POLITIC = '[politics] Get Reservation Politic';
export const SET_RESERVATION_POLITIC = '[politics] Set Reservation Politic';
export const POST_RESERVATION_POLICIES = '[politics] Post Reservation Policies';
export const DELETE_RESERVATION_POLICIES = '[politics] Delete Reservation Policies';
export const DELETE_RESERVATION_POLICIES_FORCE = '[politics] Delete Reservation Policies with Force';
export const DELETE_ALL = '[politics] Delete All';
export const PUT_RESERVATION_POLICIES = '[politics] PUT Reservation Policies';
//
export const RESERVATION_MANUAL_BLOCK_POLICIES = '[politics] Reservation Manual Block Policies';
export const POST_RESERVATION_MANUAL_BLOCK_POLICIES = '[politics] Post Reservation Manual Block Policies';
export const PUT_RESERVATION_MANUAL_BLOCK_POLICIES = '[politics] Put Reservation Manual Block Policies';
export const DELETE_RESERVATION_MANUAL_BLOCK_POLICIES = '[politics] Delete Reservation Manual Block Policies';
//
export const RESERVATION_AUTO_BLOCK_POLICIES = '[politics] Reservation Auto Block Policies';
export const POST_RESERVATION_AUTO_BLOCK_POLICIES = '[politics] Post Reservation Auto Block Policies';
export const PUT_RESERVATION_AUTO_BLOCK_POLICIES = '[politics] Put Reservation Auto Block Policies';
export const DELETE_RESERVATION_AUTO_BLOCK_POLICIES = '[politics] Delete Reservation Auto Block Policies';
//
export const RESERVATION_WHITE_LIST_POLICIES = '[politics] Reservation White List Policies';
export const POST_RESERVATION_WHITE_LIST_POLICIES = '[politics] Post Reservation White List Policies';
export const DELETE_RESERVATION_WHITE_LIST_POLICIES = '[politics] Delete Reservation White List Policies';

export const FETCH_USERS = '[politics] Fetch Users';
export const FETCH_GROUPS = '[politics] Fetch Groups';

const state = () => ({
  list: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 10,
    }
  },
  reservation: null
})

const mutations = {
  [SET_RESERVATION_POLICIES](state, payload) {
    state.list.loading = true
    state.list.data = payload.data
    state.list.meta = payload.meta
  },

  [SET_RESERVATION_POLITIC](state, payload) {
    state.reservation = payload
  }
}

const actions = {

  // Получения списка политик бронирования;
  async [FETCH_RESERVATION_POLICIES]({ commit }, params) {
    try {
      const res = await Axios.get(`/reservation-policies`, { params });
      commit(SET_RESERVATION_POLICIES, res)
      return res;
    } catch (e) {
      console.log('FETCH_RESERVATION_POLICIES', e)
      commit('setError', e, { root: true })
    }
  },

  // Создание политики бронирования / Обновление настроек политики бронирования по ID
  async [POST_RESERVATION_POLICIES]({ commit }, { formData, id = null, params = {} }) {
    let res;
    try {
      if (id) {
        res = await Axios.put(`/reservation-policies/${id}` + urlHelper.makeGetParams(params), { ...formData });
      } else {
        res = await Axios.post(`/reservation-policies` + urlHelper.makeGetParams(params), { ...formData });
      }
      return res;
    } catch (e) {
      commit('setError', e, { root: true })
      return false;
    }
  },
  // Обновить политики бронирования по ID
  async [PUT_RESERVATION_POLICIES]({ commit }, { formData, id = null, params = {} }) {
    if (!id) return false;
    try {
      const res = await Axios.put(`/reservation-policies/${id}` + urlHelper.makeGetParams(params), { ...formData });
      commit(SET_RESERVATION_POLICIES, res);
      return res;
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },
  // Удаление политики бронирования по ID
  async [DELETE_RESERVATION_POLICIES]({ commit }, id) {
    if (!id) return false;
    try {
      return await Axios.delete(`/reservation-policies/${id}`,);
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },
  async [DELETE_RESERVATION_POLICIES_FORCE]({ commit }, id) {
    if (!id) return false;
    try {
      return await Axios.delete(`/reservation-policies/${id}?force=true`,);
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },
  // Массовое удаление сущностей
  async [DELETE_ALL]({ commit }, { ids = [], entityType }) {
    try {
      return await Axios.post(`/trash`, { ids, entityType });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Получение политики бронирования по ID
  async [RESERVATION_POLITIC]({ commit }, id) {
    if (!id) return false;
    try {
      const res = await Axios.get(`/reservation-policies/${id}`);
      commit(SET_RESERVATION_POLITIC, res)
      return res;
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Получение списка ручных блокировок пользователей или групп
  async [RESERVATION_MANUAL_BLOCK_POLICIES]({ commit }, { id, params }) {
    if (!id) return false;
    try {
      return await Axios.get(`/reservation-policies/${id}/manual-block-policies`, { params });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Создание новой ручной блокировки пользователя или группы
  async [POST_RESERVATION_MANUAL_BLOCK_POLICIES]({ commit }, { formData }) {
    if (!formData.id) return false;
    try {
      return await Axios.post(`/reservation-policies/${formData.id}/manual-block-policies`, { ...formData });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Редактирование ручной политики блокировки
  async [PUT_RESERVATION_MANUAL_BLOCK_POLICIES]({ commit }, { formData, id = null, id2 = null }) {
    if (!id && !id2) return false;
    try {
      return await Axios.put(`/reservation-policies/${id}/manual-block-policies/${id2}`, { ...formData });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Удаление ручной политики блокировки
  async [DELETE_RESERVATION_MANUAL_BLOCK_POLICIES]({ commit }, { id = null, id2 = null }) {
    if (!id && !id2) return false;
    try {
      return await Axios.delete(`/reservation-policies/${id}/manual-block-policies/${id2}`);
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Получение списка авто блокировок пользователей или групп
  async [RESERVATION_AUTO_BLOCK_POLICIES]({ commit }, { id, params }) {
    if (!id) return false;
    try {
      return await Axios.get(`/reservation-policies/${id}/auto-block-policies`, { params });

    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Создание новой авто блокировоки пользователей или групп
  async [POST_RESERVATION_AUTO_BLOCK_POLICIES]({ commit }, { formData, id = null }) {
    if (!id) return false;
    try {
      return await Axios.post(`/reservation-policies/${id}/auto-block-policies`, { ...formData });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Редактирование политики авто-блокировки
  async [PUT_RESERVATION_AUTO_BLOCK_POLICIES]({ commit }, { formData, id = null, id2 = null }) {
    if (!id) return false;
    try {
      if (id2) {
        return await Axios.put(`/reservation-policies/${id}/auto-block-policies/${id2}`, { ...formData });
      } else {
        return await Axios.put(`/reservation-policies/${id}/auto-block-policies/`, { ...formData });
      }

    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Удаление политики авто-блокировки
  async [DELETE_RESERVATION_AUTO_BLOCK_POLICIES]({ commit }, { id = null, id2 = null }) {
    if (!id && !id2) return false;
    try {
      return await Axios.delete(`/reservation-policies/${id}/auto-block-policies/${id2}`);
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Полчучить список пользвателей;
  async [FETCH_USERS]({ commit }, params) {
    try {
      const { data } = await Axios.get(`/users`, { params });
      return data;
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Полчучить список группы;
  async [FETCH_GROUPS]({ commit }, params) {
    try {
      return await Axios.get(`/groups`, { params });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Получить список белого листа;
  async [RESERVATION_WHITE_LIST_POLICIES]({ commit }, { id, params }) {
    try {
      return await Axios.get(`/reservation-policies/${id}/whitelists`, { params });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Создание новой авто блокировоки пользователей или групп
  async [POST_RESERVATION_WHITE_LIST_POLICIES]({ commit }, { formData, id = null }) {
    if (!id) return false;
    try {
      return await Axios.post(`/reservation-policies/${id}/whitelists`, { ...formData });
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },

  // Удаление политики авто-блокировки
  async [DELETE_RESERVATION_WHITE_LIST_POLICIES]({ commit }, { id = null, id2 = null }) {
    if (!id && !id2) return false;
    try {
      return await Axios.delete(`/reservation-policies/${id}/whitelists/${id2}`);
    } catch (e) {
      commit('setError', e, { root: true })
    }
  },
}

const getters = {
  [GET_RESERVATION_POLICIES]: (state) => state.list,
  [GET_RESERVATION_POLITIC]: (state) => state.reservation,
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
