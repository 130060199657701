export const FAVORITES = '[favorites] Favorites';

const state = () => ({
  data: []
})

const mutations = {
  [FAVORITES](state, payload) {
    state.data = payload
  },
}

const actions = {
  [FAVORITES]({ commit }, payload) {
    console.log('FAVORITES',payload);
    commit(FAVORITES, payload)
  },
}

const getters = {
  [FAVORITES]: (state) => state.data,
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
