import { createRouter, createWebHistory } from "vue-router";
import { urlHelper } from "@/compositions/commonFunctions";
import Root from "@/pages/Root";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "index",
      meta: {
        title: 'Главная',
        
      },
      component: () => import("./pages/Index"),
    },

    {
      path: "/main",
      name: "main",
      meta: {
        title: 'Главная',
        menuItem: 'main',
      },
      component: () => import("./pages/Main"),
    },

    {
      path: "/uikit228",
      name: "uikit228",
      meta: {
        title: 'UI Kit',
      },
      component: () => import("./pages/UiKit"),
    },
    {
      path: "/favourites",
      meta: {
        title: 'Избранные места',
        menuItem: 'favourites',
      },
      component: Root,
      children: [

        {
          path: "",
          name: "favourites",
          meta: {
            title: 'Избранные места',
            menuItem: 'favourites',
          },
          component: () => import("./pages/user/favourites/List"),
        },

        {
          path: "workplaces",
          name: "workplaces",
          meta: {
            title: 'Рабочие места',
            menuItem: 'favourites',
          },
          component: () => import("./pages/user/favourites/WorkPlaces"),
        },

        {
          path: "meetingRooms",
          name: "meetingRooms",
          meta: {
            title: 'Переговорные',
            menuItem: 'favourites',
          },
          component: () => import("./pages/user/favourites/MeetingRooms"),
        },

        {
          path: "parkingLots",
          name: "parkingLots",
          meta: {
            title: 'Парковки',
            menuItem: 'favourites',
          },
          component: () => import("./pages/user/favourites/ParkingLots"),
        },

        {
          path: "lockers",
          name: "lockers",
          meta: {
            title: 'Локеры',
            menuItem: 'favourites',
          },
          component: () => import("./pages/user/favourites/Lockers"),
        }
      ],
    },
    {
      path: "/maps",
      component: Root,
      meta: {
        menuItem: 'maps',
      },
      children: [
        {
          path: ":id", component: Root,
          children: [
            {
              path: "",
              name: "mapView",
              component: () => import("./pages/common/MapView"),
            },
          ],
        },
      ],
    },
    {
      path: "/reservations",
      component: Root,
      meta: {
        menuItem: 'reservations',
      },
      children: [
        {
          path: "",
          name: "myReservations",
          component: () => import("./pages/user/reservations/List"),
          meta: {
            title: 'Мои бронирования',
          },
        },
        {
          path: "find",
          name: "findSlots",
          component: () => import("./pages/user/reservations/ListSlots"),
          meta: {
            title: 'Свободные места',
            menuItem: 'slots',
          },
        },
        {
          path: ":id",
          name: "reservationSchedule",
          component: () => import("./pages/user/reservations/ReservationSchedule"),
        },
      ],
    },
    {
      path: "/admin", component: Root,
      children: [
        {
          path: "",
          name: "adminIndex",
          component: () => import("./pages/Index"),
        },
        {
          path: "objects", component: Root,
          meta: {
            menuItem: 'objects',
          },
          children: [
            {
              path: "",
              name: "objects",
              component: () => import("./pages/admin/objects/List"),
              meta: {
                title: 'Структура',
              },
            },
            {
              path: "svg-map",
              name: "objectsSvgMap",
              component: () => import("./pages/admin/objects/SvgMap"),
              meta: {
                title: 'SVG-карта корня',
              },
              children: [],
            },
            {
              path: ":id", component: Root,
              children: [
                {
                  path: "",
                  name: "objectChildren",
                  component: () => import("./pages/admin/objects/List"),
                },
                {
                  path: "edit",
                  name: "objectView",
                  component: () => import("./pages/admin/objects/ObjectEdit"),
                },
              ],
            },
          ],
        },
        {
          path: "types", component: Root,
          meta: {
            menuItem: 'types',
          },
          children: [
            {
              path: "",
              name: "types",
              component: () => import("./pages/admin/object-types/List"),
              meta: {
                title: 'Типы объектов',
              },
            },
            {
              path: ":id", component: Root,
              children: [
                {
                  path: "",
                  name: "typeView",
                  component: () => import("./pages/admin/object-types/TypeEdit"),
                },
              ],
            },
          ],
        },
        {
          path: "politics", component: Root,
          meta: {
            menuItem: 'politics',
          },
          children: [
            {
              path: "",
              name: "politics",
              component: () => import("./pages/admin/politics/List"),
              meta: {
                title: 'Политика доступов',
              },
            },
            {
              path: ":id", component: Root,
              children: [
                {
                  path: "",
                  name: "politicView",
                  component: () => import("./pages/admin/politics/PoliticEdit"),
                },
              ],
            },
          ],
        },
        {
          path: "maps",
          component: Root,
          meta: {
            menuItem: 'maps',
          },
          children: [
            {
              path: "",
              name: "maps",
              component: () => import("./pages/admin/maps/List"),
              meta: {
                title: 'Карты',
              },
            },
            {
              path: ":id", component: Root,
              children: [
                {
                  path: "",
                  name: "mapViewAdmin",
                  component: () => import("./pages/common/MapView"),
                },
              ],
            },
          ],
        },
        {
          path: "reservations",
          component: Root,
          meta: {
            menuItem: 'reservations',
          },
          children: [
            {
              path: "",
              name: "reservations",
              component: () => import("./pages/admin/reservations/List"),
              meta: {
                title: 'Бронирования',
              },
            },
            {
              path: ":id", component: Root,
              children: [
                {
                  path: "",
                  name: "reservationView",
                  component: () => import("./pages/admin/reservations/ReservationEdit"),
                },
              ],
            },
          ]
        },
      ]
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./pages/common/Page404"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404"
    },
  ],
});

router.beforeEach((to, from, next) => {
  urlHelper.setPageTitle(to.meta.title);
  next();
});
