export const VIEW_PORT_HEIGHT = '[shared] View Port Height';
export const VIEW_PORT_WIDTH = '[shared] View Port Width';
export const SCROLL_POSITION = '[shared] Scroll Position';
const state = () => ({
    error: null,
    viewportWidth: 0,
    viewportHeight: 0,
    scrollPosition: 0,
})
const actions = {}

const mutations = {
    setError(state, error) {
        state.error = error
    },
    clearError(state) {
        state.error = null
    },
    setViewportWidth(state, value) {
        state.viewportWidth = value;
    },
    setViewportHeight(state, value) {
        state.viewportHeight = value;
    },
    setScrollPosition(state, value) {
        state.scrollPosition = value;
    },
}

const getters = {
    error: state => state.error,
    [VIEW_PORT_HEIGHT]: (state) => state.viewportHeight,
    [VIEW_PORT_WIDTH]: (state) => state.viewportWidth,
    [SCROLL_POSITION]: (state) => state.scrollPosition,
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
