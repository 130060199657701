export const USER_PROFILE = '[settings] User Profile';
export const USER_PROFILE_CLOSE = '[settings] User Profile Close';
const state = () => ({
  profile: {
     user: null,
     isShowDrawer: false
  }
})

const mutations = {
  [USER_PROFILE](state, payload) {
    if(payload) state.profile = payload
  },

  [USER_PROFILE_CLOSE](state) {
    state.profile = {
      user: null,
      isShowDrawer: false
    }
  },
}

const actions = {
  [USER_PROFILE]({ commit }, payload) {
    commit(USER_PROFILE, payload)
  },

  [USER_PROFILE_CLOSE]({ commit }, ) {
    commit(USER_PROFILE_CLOSE)
  },
}

const getters = {
  [USER_PROFILE]: (state) => state.profile,
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
