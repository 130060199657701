import moduleShared from './store/shared'
import moduleCabinet from './store/cabinet'
import modulePolitics from './store/politics'
import moduleProfile from './store/settings'
import moduleFavorites from './store/favorites'
export const store = {
  modules: {
    shared: moduleShared,
    cabinet: moduleCabinet,
    politics: modulePolitics,
    settings: moduleProfile,
    favorites: moduleFavorites
  },
}
