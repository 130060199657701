import {
  Layout, Space, Divider, Col, Row, PageHeader,
  DatePicker, Breadcrumb,
  Badge, Descriptions, Tag, Skeleton,
  Result, Card,
  Drawer, Empty, Menu, Tabs,
  Table, Collapse, Carousel, TreeSelect,
  Tooltip, Image,
  Form, Select, Radio, Button, Dropdown, TimePicker, Input, InputNumber, Switch, Upload,
  Popconfirm, Modal,
  Progress, Spin, Avatar, Affix, Statistic, Checkbox, Tree, Popover, Pagination, ConfigProvider
} from 'ant-design-vue';

// Override styles
import './assets/less/style.less';

export function useAntDesign(app) {
  app.config.productionTip = false;
  app.use(Layout)
    .use(ConfigProvider)
    .use(Affix)
    .use(Carousel)
    .use(Avatar)
    .use(PageHeader)
    .use(Pagination)
    .use(Descriptions)
    .use(Collapse)
    .use(Popover)
    .use(Space)
    .use(Button)
    .use(Dropdown)
    .use(Radio)
    .use(DatePicker)
    .use(TimePicker)
    .use(Badge)
    .use(Spin)
    .use(Skeleton)
    .use(Result)
    .use(Card)
    .use(Divider)
    .use(Progress)
    .use(Col)
    .use(Row)
    .use(Empty)
    .use(Image)
    .use(Drawer)
    .use(Popconfirm)
    .use(Modal)
    .use(Table)
    .use(Tree)
    .use(TreeSelect)
    .use(Statistic)
    .use(Tabs)
    .use(Breadcrumb)
    .use(Tooltip)
    .use(Form)
    .use(Input)
    .use(InputNumber)
    .use(Switch)
    .use(Menu)
    .use(Select)
    .use(Tag)
    .use(Checkbox)
    .use(Upload);
}
